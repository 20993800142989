$highlight: $primary-color;

.responsive-blog-footer {
  background: darken($farbe1, 20%);
  padding: 3rem 3rem;
  color: white;

  // h4 {
  //   @include breakpoint(medium down) {
  //     font-size: 1.5rem;
  //   }
  // }

  p {
    color: $farbe3;
  }

  .social-container {
    margin-bottom: 2rem;
  }

  .about-section, .tag-section {
    margin-bottom: 2rem;

    a {
      color: $highlight;
    }
  }
}


.im {
  font-size: 2rem;
  color: $light-gray;

   &:hover {
   color: lighten($dark-gray, 15%);
   transition: color 0.3s ease-in;
  }

  @include breakpoint(medium down) {
    font-size: 1.6rem;
  }
}

a img { border: none; }

.content-section {
  min-height: 100vh;

  &.bgc-farbe1 {
    background-color: $farbe1;
  }
  &.bgc-farbe2 {
    background-color: $farbe2;
  }
  &.bgc-farbe3 {
    background-color: $farbe3;
  }
  &.bgc-farbe4 {
    background-color: $farbe4;
  }

  .subheadline {
    color: $subheadline;
  }

  .lead {
    text-transform: uppercase;
  }
}

.content-section-block {
  width: 100%;

  .content-section-txt {
    width: 100%;
  }
}

.up-down-arrow {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @include breakpoint(medium down) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

$transition: .15s ease all;

a {
  color: pink;
  outline: 0;
  text-decoration: none;
  transition: $transition;
}

i {
  font-size: 1.8rem;
  color: $light-gray;
}

$buch-hero-height: 100vh;

.buch-hero {
  background: url('../../assets/img/Nebelgift-Hero.jpg');
  height: $buch-hero-height;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  display: flex;
  align-items: center;

  @include breakpoint(large) {
    background-position: center center;
  }
}

.buch-hero-content {
  max-width: $global-width;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;

  // h1 {
  //   font-size: 32px;
  // }

  .button {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
  }
  @include breakpoint(large) {
    padding-left: 26%;
  }
}

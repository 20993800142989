body {
  animation: fadein 0.5s;
}

.browserupgrade {
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
